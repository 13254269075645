import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentRsUser } from '@app/core/state/user/user.selectors';

export const FrontPlateGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);
  const currentUser = store.selectSignal(selectCurrentRsUser)()

  const frontPlateLeasingCompanyNumbers = ['799110', '801885', '799230', '799150'];
  const hasAccess = frontPlateLeasingCompanyNumbers.some(lcNumber => currentUser?.rsNumber.includes(lcNumber));

  return hasAccess ? true : router.parseUrl('/rs-number');
};
