import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CreatePackageOrder } from '@distribution-center/package-orders/shared/models/create-package-order';
import { RsFile, UUID } from '@lib/rs-ngx';
import { CreateRegistrationPackageOrder } from '@app/registrations-v2/shared/models/create-registration-package-order';
import { RegistrationV2 } from '@app/registrations-v2/details-section/shared/models/registration';
import { PackageOrderDelivery } from '@app/registrations-v2/details-section/shared/models/package-order-delivery';
import { recursiveDateFormatter } from '@lib/utils/date-formatter';
import { PrefillRegistration } from '@app/registrations-v2/details-section/shared/models/prefill-registration';
import { RegistrationDetails } from '@app/registrations-v2/details-section/shared/models/registration-details';
import { RegistrationSummary } from '@app/registrations-v2/details-section/shared/models/registration-summary';

@Injectable({
  providedIn: 'root'
})
export class RegistrationV2Service {
  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  public readonly createRegistration = (body: RegistrationV2): Observable<UUID> => {
    const formattedBody = recursiveDateFormatter(body);
    return this.httpClient.put<string>(`${environment.apiUrl}/v2/registration`, formattedBody);
  };

  public readonly savePackageOrder = (registrationId: string, registrationPackageOrder: CreateRegistrationPackageOrder, documents: RsFile[] | null): Observable<CreatePackageOrder> => {
    const formData = this.createPackageOrderFormData(registrationPackageOrder, documents);
    return this.httpClient.post<CreatePackageOrder>(
      `${environment.apiUrl}/v2/registration/${registrationId}/package-order`,
      formData
    );
  }

  public readonly getPackageOrderDeliveryAddresses = (registrationId: string): Observable<PackageOrderDelivery> => {
    return this.httpClient.get<PackageOrderDelivery>(`${environment.apiUrl}/v2/registration/${registrationId}/package-order-delivery`);
  }

  public readonly prefilledRegistration = (linkedOrderId: string): Observable<PrefillRegistration> => {
    return this.httpClient.get<PrefillRegistration>(`${environment.apiUrl}/v2/registration/form?orderId=${linkedOrderId}`);
  }

  public readonly getRegistrationDetails = (registrationId: string): Observable<RegistrationDetails> => {
    return this.httpClient.get<RegistrationDetails>(`${environment.apiUrl}/v2/registration/${registrationId}`);
  }

  public readonly getRegistrationSummary = (registrationId: UUID): Observable<RegistrationSummary> => {
    return this.httpClient.get<RegistrationSummary>(`${environment.apiUrl}/v2/registration/${registrationId}/summary`);
  }

  private createPackageOrderFormData(packageOrder: CreateRegistrationPackageOrder, files: RsFile[] | null): FormData {
    const formData = new FormData();

    formData.append(
      'packageOrder',
      new Blob([JSON.stringify(packageOrder.packageOrder)], { type: 'application/json' })
    );

    if (files) {
      const filesData = files.map(file => file.data);
      filesData.forEach(fileData => {
        formData.append('documents', fileData, fileData.name);
      });
    }

    return formData;
  }
}
